<template>
  <window-content v-if="!preventBind" class="financeiro-window">
    <div class="w-m-header">
      <div style="line-height: 1">
        <h2 v-if="!doc.id">Pedido de RGI</h2>
        <h2 v-else>Pedido de RGI<!--{{ doc.nome }}--></h2>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do pedido</legend>
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 200px; min-height: 32px;">
            <erp-s-field view="ll" label="Situação">
              <erp-select :style="doc.status === 0 ? 'background-color: #ff7878;' : 'background-color: #e0ffbb;'" v-model="doc.status" :options="[
                  {label: 'Pendente', value: 0},
                  {label: 'Entregue', value: 1},
              ]"/>
            </erp-s-field>
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 140px; min-height: 32px">
            <erp-s-field view="ll" :label="!doc.active ? 'Desativado' : 'Ativo'">
              <sl-checkbox no-yes-no-val v-model="doc.active" id="active"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field label="Processo">
              <global-processo-input v-model="doc.processo" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Data Pedido">
              <datetime-input date-type="date" date-format="##/##/####" disable v-model="doc.dataPedido" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Data Entrega">
              <datetime-input date-type="date" date-format="##/##/####" disable v-model="doc.dataEntrega" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field label="Matrícula">
              <erp-input v-model="doc.matricula" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Transcrição">
              <erp-input v-model="doc.transcricao" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="CRI">
              <erp-input v-model="doc.cri" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Comarca">
              <erp-input v-model="doc.comarca" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col style="max-width: 260px">
            <erp-s-field label="Valor">
              <erp-input v-model="doc.valor" v-money="money" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="Observações">
              <textarea class="sl-textarea full-width" rows="4" v-model="doc.observacoes" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field label="Upload do documento">
              <input type="file" id="file" v-on:change="selectFile" />
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
      <div v-if="doc.arquivos && doc.arquivos.length" class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Histórico</legend>
          </e-col>
        </e-row>
        <e-row v-for="arquivo in doc.arquivos" :key="arquivo.id">
          <e-col><a @click="downloadArquivo(arquivo)">{{arquivo.originalFilename}}</a></e-col>
          <e-col>Criado em {{arquivo.createdAt|formatDate}}</e-col>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Voltar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar Certidão" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right__"/>
<!--        <u-btn icon="sort-down" icon-type="fa" no-caps class="p-btn-down normal b-radius-3px font-12 text-white">
          <u-popover ref="popover" class="window-context-menu" style="width: 192px" anchor="bottom right"
                     self="top right" :offset="[0, 0]">
            <div>
              <ul>
                <menu-options-item @click="save('andEdit')" label="Salvar e continuar editando" close/>
                <menu-options-item @click="save('andNew')" label="Salvar e criar novo" close/>
              </ul>
            </div>
          </u-popover>
        </u-btn>-->
      </div>
    </div>
  </window-content>
</template>

<script>
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
import {
  newControleRgi,
  updateControleRgi,
  findControleRgi as find,
  uploadDocumento,
  downloadDocumento
} from "@/domain/controleRgi/services/index"
import mockdoc from "@/domain/controleRgi/helpers/mockDocumento"
import SlCheckbox from "@/reuse/input/Checkbox"
import DatetimeInput from "@/reuse/input/Datetime"
import {fileToBase64} from "@/utils/fileToBase64"
import {datePtToEn} from "@/utils/date"
import GlobalProcessoInput from "components/processos/components/processos/include/ProcessoInput"
import {VMoney} from "v-money"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {format} from "date-fns";

export default {
  name: 'docWindow',
  provide: function () {
    return {
      container: this
    }
  },
  directives: {money: VMoney},
  mixins: [],
  components: {
    GlobalProcessoInput,
    DatetimeInput,
    ErpSelect,
    SlCheckbox,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    UTooltip,
    WindowContent
  },
  props: ['id', 'options', 'router'],
  data() {
    const doc = JSON.parse(JSON.stringify(mockdoc))
    doc.dataPedido = format(new Date(), 'dd/MM/yyyy')
    return {
      money: REAL_BRL,
      loading: false,
      editing: false,
      success: false,
      status: '',
      preventBind: true,
      doc: doc
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {},
  computed: {
  },
  methods: {
    load() {
      this.loading = true
      find(this.id)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.doc.id = response.data.id
            }
            this.preventBind = true
            this.doc = Object.assign({}, this.doc, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            console.log(this.doc)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.doc))
      const relacoes = ['solicitante', 'processo']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })
      const datas = ['dataPedido', 'dataEntrega']
      datas.map(key => {
        if (data[key]) {
          data[key] = datePtToEn(data[key])
        }
      })
      data.valor = convertRealToMoney(data.valor)
      return data
    },
    prepareToEdit(data) {
      const datas = ['dataPedido', 'dataEntrega']
      datas.map(key => {
        if (data[key]) {
          data[key] = this.$options.filters.formatDate(data[key])
        }
      })
      data.valor = 'R$ ' + this.$options.filters.moeda(data.valor || 0)
      return data
    },
    async save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      const submit = () => {
        let method = newControleRgi
        if (this.doc.id) {
          method = updateControleRgi
        }
        method(data)
            .then(async response => {
              this.loading = false
              console.log(response)
              const isEditing = this.isEditing
              this.doc.id = response.data.id
              if (this.$file) {
                // need to upload file
                console.log(this.$file)
                this.$uloc.loading.show()
                try {
                  await uploadDocumento(this.doc.id, {data: this.$file.file, filename: this.$file.name})
                      .then(({data}) => {
                        delete this.$file
                      })
                      .catch((error) => {
                        this.$uloc.notify({
                          color: 'negative',
                          message: `Não foi possível enviar o arquivo, mas os demais dados foram processados com sucesso!`,
                          position: 'bottom-left',
                        })
                      })
                } catch (e) {}
                this.$uloc.loading.hide()

              }
              if (this.id) {
                this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
                this.success = true
                this.dg()
                this.$uloc.notify({
                  color: 'positive',
                  message: `Pedido alterado.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
                this.load()
              } else {
                this.$uloc.window.emit(this.$root.wid, 'created', response.data)
                this.$uloc.notify({
                  color: 'black',
                  message: `#Pedido ${this.id ? 'modificado' : 'criado'}.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
                this.$uloc.window.close(this.$root.wid)
              }
              if (type === 'andNew') {
                this.preventBind = true
                this.doc = JSON.parse(JSON.stringify(mockdoc))
                this.$nextTick(() => {
                  this.preventBind = false
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.alertApiError(error)
            })
      }
      submit()
    },
    selectFile (e) {
      if (e.target.files[0]) {
        this.$file = e.target.files[0];
        fileToBase64(this.$file)
            .then(file => {
              this.$file.file = file
            })
            .catch(error => {
              console.error(error)
            })
        return
      }
      delete this.$file
    },
    downloadArquivo (arquivo) {
      downloadDocumento(this.doc.id, arquivo.id, true)
          .then(response => {
            console.log(response.data)
            const link = document.createElement('a')
            link.href = response.data.url
            link.target = '_blank'
            document.body.appendChild(link)
            link.click();
            window.setTimeout(() => {
              document.body.removeChild(link)
              arquivo.downloading = false
            }, 100)
            // window.open(response.data.url)
          })
          .catch(error => {
            arquivo.downloading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
